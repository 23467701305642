/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import ApplicationContext from "../../../context/ApplicationContext";

import { Button, Form, Modal, Row } from "react-bootstrap";
import { ILoginProps } from "./ILoginProps";
import { authenticate } from "../../../services/Authenticate";
import { IAuth } from "../../../types/IAuth";
import KeyboardWrapper from "../../../helpers/KeyboardWrapper";

import "react-simple-keyboard/build/css/index.css";

export const Login: React.FC<ILoginProps> = (
  props: ILoginProps
): JSX.Element => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Login failed");
  const [focused, setFocused] = useState("");

  const [showSupportModal, setShowSupportModal] = React.useState(false);
  const [loginInProgress, setLoginInProgress] = React.useState(false);
  const [showKeyboard, setShowKeyboard] = React.useState(false);
  const keyboard = useRef();

  const context = useContext(ApplicationContext);

  useEffect(() => {
    const user = window.localStorage.getItem("username");
    const pass = window.localStorage.getItem("password");

    setUsername(user !== null ? user : "");
    setPassword(pass != null ? pass : "");
  }, []);

  async function handleLogin(e: any) {
    setLoginInProgress(true);
    e.preventDefault();

    if (username.length === 0 || password.length === 0) {
      setShowError(true);
      setLoginInProgress(false);
      return;
    }

    var auth = await authenticate(username, password);

    if (!auth) {
      setShowError(true);
      return;
    }

    if (auth.status !== 200) {
      setShowError(true);
      setLoginInProgress(false);
      return;
    }

    const data: IAuth = auth.data;
    context.AuthenticatedUser.setUser(data.user);
    context.AuthenticatedUser.setToken(data.tokenValue);

    setLoginInProgress(false);
    props.successful();
    setShowError(false);
  }

  const onUsernameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    setUsername(input);

    if (showKeyboard) {
      // @ts-ignore
      keyboard.current.setInput(input);
    }
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    setPassword(input);

    if (showKeyboard) {
      // @ts-ignore
      keyboard.current.setInput(input);
    }
  };

  const onKeyboardChange = (input: any) => {
    if (focused === "username") {
      setUsername(input);
    }
    if (focused === "password") {
      setPassword(input);
    }
  };

  function handleForgotPassword() {
    setShowSupportModal(true);
  }

  function onEnterHit(e: any) {
    if (e.key === "Enter") {
      handleLogin(e);
    }
  }

  const supportModal = (): JSX.Element => {
    return (
      <>
        <Modal
          show={showSupportModal}
          onHide={() => setShowSupportModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <br />
            <div
              style={{
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h2>Please contact support on</h2>
              <h1>07977-786786</h1>
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{
                width: 150,
                height: 50,
                fontSize: "16px",
                fontWeight: "bolder",
              }}
              onClick={() => setShowSupportModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function toggleKeyboard() {
    //do something here
    let value = showKeyboard;

    if (value === true) {
      setShowKeyboard(false);
    } else {
      setShowKeyboard(true);
    }
  }

  const loginForm = (): JSX.Element => {
    return (
      <>
        <div
          style={{
            backgroundColor: "white",
            width: 350,
            height: 400,
            borderRadius: "15px",
            textAlign: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontWeight: "bolder",
              fontSize: "40px",
              fontFamily: "sans-serif",
              paddingTop: 10,
            }}
          >
            EPOS
          </div>
          <div
            style={{
              fontWeight: "bolder",
              fontSize: "14px",
            }}
          >
            <a href="https://WaEPOS.com" target="_blank">
              WaEPOS
            </a>
          </div>
          <div
            style={{
              padding: 30,
            }}
          >
            <div className="form-group">
              {showError ? (
                <div className="alert alert-danger" role="alert">
                  {errorMsg}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="form-group">
              <Form.Control
                placeholder={"Username"}
                aria-label="Username"
                aria-describedby="basic-addon1"
                style={{ fontSize: "20px", width: "100%" }}
                value={username}
                onFocus={() => setFocused("username")}
                onChange={onUsernameChange}
              />
            </div>
            <div className="form-group">
              <Form.Control
                type="password"
                placeholder={"Password"}
                aria-label="Password"
                aria-describedby="basic-addon1"
                style={{ fontSize: "20px", width: "100%" }}
                value={password}
                onFocus={() => setFocused("password")}
                onChange={onPasswordChange}
              />

              <Row style={{ paddingTop: 10 }}>
                <Button
                  variant="link"
                  className="tx-info tx-12 d-block mg-t-10"
                  onClick={handleForgotPassword}
                >
                  Forgot password?
                </Button>
                <Button
                  variant="link"
                  className="tx-info tx-12 d-block mg-t-10"
                  onClick={() => toggleKeyboard()}
                  style={{ paddingLeft: 100 }}
                >
                  Keyboard
                </Button>
              </Row>
            </div>
            <Button
              variant="primary"
              className="btn btn-primary btn-block"
              onClick={handleLogin}
              disabled={loginInProgress}
              style={{ height: 50, fontSize: 16 }}
            >
              {loginInProgress ? "Authenticating ..." : <>Login</>}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const KeyboardView = (): JSX.Element => {
    return (
      <>
        {showKeyboard ? (
          <div
            style={{
              width: "1024px",
            }}
          >
            <KeyboardWrapper
              keyboardRef={keyboard}
              onChange={onKeyboardChange}
            />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const login = (): JSX.Element => {
    return (
      <form onKeyUp={onEnterHit}>
        <div
          style={{
            backgroundColor: "#1D2939",
            width: "100vw",
            height: "100vh",
            textAlign: "center",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <table>
            <tr>
              <td
                style={{
                  backgroundColor: "#1D2939",
                  textAlign: "center",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {loginForm()}
              </td>
            </tr>
            <tr>
              <td style={{ alignItems: "center" }}>{KeyboardView()}</td>
            </tr>
          </table>
        </div>
      </form>
    );
  };

  return (
    <>
      {supportModal()}
      <div>{login()}</div>
    </>
  );
};
