import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ApplicationContext from "../../../context/ApplicationContext";
import {
  Button,
  Card,
  CardHeader,
  Form,
  ListGroup,
  Modal,
} from "react-bootstrap";
import "react-simple-keyboard/build/css/index.css";
import { ICustomerProps } from "./ICustomerProps";
import { ICustomer } from "../../../types/ICustomer";
import { Table } from "semantic-ui-react";
import { GenerateGUID } from "../../../helpers/Helper";
import { ITelephone } from "../../../types/ITelephone";
import { getTelephoneData } from "../../../services/Service";
import KeyboardWrapper from "../../../helpers/KeyboardWrapper";

export const CustomerModal: React.FC<ICustomerProps> = (
  props: ICustomerProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [name, setName] = React.useState("");
  const [telephone, setTelephone] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [address, setAddress] = React.useState("");

  const [callerData, setCallerData] = React.useState<ITelephone[]>([]);

  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState("");

  const [focused, setFocused] = useState("");
  const [layout, setLayout] = useState("default");
  const keyboard = useRef();

  const onKeyboardChange = (input: any) => {
    if (focused === "name") {
      setName(input);
    }
    if (focused === "telephone") {
      setTelephone(input);
    }
    if (focused === "postcode") {
      setPostcode(input);
    }
    if (focused === "address") {
      setAddress(input);
    }
  };

  const onNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    setName(input);
    // @ts-ignore
    keyboard.current.setInput(input);
  };
  const onPostcodeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    setPostcode(input);
    // @ts-ignore
    keyboard.current.setInput(input);
  };

  const onAddressChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    setAddress(input);
    // @ts-ignore
    keyboard.current.setInput(input);
  };

  const onTelephoneChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const input = event.target.value;
    setTelephone(input);
    // @ts-ignore
    keyboard.current.setInput(input);
  };

  function closeModal() {
    props.close();
  }

  function formatAMPM(dt: Date) {
    const date = new Date(dt);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let newMinutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + newMinutes + " " + ampm;
    return strTime;
  }

  function copyData(uuid: string) {
    const contact = callerData.find((t) => t.uuid === uuid);

    if (contact === undefined) {
      return;
    }

    setTelephone(contact.contact);

    if (contact.customer !== null) {
      setName(contact.customer.name);
      setAddress(contact.customer.address);
      setPostcode(contact.customer.postcode);
    }
  }

  async function updateRecentCalls() {
    setLoading(true);

    const response = await getTelephoneData(
      context.AuthenticatedUser.getToken()
    );

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.CallData.setData(response);

    setCallerData(response);
    selectTopCaller(response);
    setRefresh(GenerateGUID());

    setLoading(false);
  }

  function refreshRecentCalls() {
    updateRecentCalls();
  }

  function selectTopCaller(data: ITelephone[]) {
    if (data.length == 0) {
      return;
    }

    copyData(data[0].uuid);
  }
  useEffect(() => {
    if (props.show) {
      updateRecentCalls();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.show]);

  const customerModal = (): JSX.Element => {
    return (
      <>
        <Modal show={props.show} onHide={() => closeModal()} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <div
                style={{
                  height: "100%",
                  padding: "15px",
                  marginLeft: "auto",
                  marginRight: "0",
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <table>
                  <tr>
                    <td
                      style={{
                        width: "400px",
                        backgroundColor: "whitesmoke",
                      }}
                    >
                      <table>
                        <tr>
                          <td
                            style={{
                              backgroundColor: "whitesmoke",
                              width: "100%",
                            }}
                          >
                            <Card style={{ width: "100%" }}>
                              <CardHeader>Recent calls</CardHeader>
                              <div
                                style={{
                                  overflowY: "auto",
                                  height: "200px",
                                }}
                              >
                                <Table selectable>
                                  <Table.Body>
                                    {callerData.map((data) => (
                                      <Table.Row
                                        onClick={() => {
                                          copyData(data.uuid);
                                        }}
                                      >
                                        <Table.Cell width={1}>
                                          <h5>
                                            {data.contact}
                                            {"  ("}
                                            {formatAMPM(data.createdAt)}
                                            {")  "}
                                          </h5>
                                          {data.customer !== undefined ? (
                                            <></>
                                          ) : (
                                            <></>
                                          )}
                                        </Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </Card>
                          </td>
                          <td></td>
                        </tr>
                      </table>
                    </td>
                    <td
                      style={{
                        backgroundColor: "",
                        padding: 5,
                        verticalAlign: "top",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <Form.Control
                          placeholder={"Name"}
                          aria-label="Name"
                          aria-describedby="basic-addon1"
                          autoFocus={true}
                          style={{ fontSize: "20px", width: "100%" }}
                          value={name}
                          onFocus={() => setFocused("name")}
                          onChange={onNameChange}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                          paddingTop: 5,
                        }}
                      >
                        <Form.Control
                          placeholder="Telephone"
                          aria-label="Telephone"
                          aria-describedby="basic-addon1"
                          style={{ fontSize: "20px", width: "100%" }}
                          value={telephone}
                          onFocus={() => setFocused("telephone")}
                          onChange={onTelephoneChange}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                          paddingTop: 5,
                        }}
                      >
                        <Form.Control
                          placeholder="Postcode"
                          aria-label="Postcode"
                          aria-describedby="basic-addon1"
                          style={{ fontSize: "20px", width: "100%" }}
                          value={postcode}
                          onFocus={() => setFocused("postcode")}
                          onChange={onPostcodeChange}
                        />
                      </div>
                      <div
                        style={{
                          width: "100%",
                          justifyContent: "center",
                          display: "flex",
                          paddingTop: 5,
                        }}
                      >
                        <Form.Control
                          placeholder="Address"
                          aria-label="Address"
                          aria-describedby="basic-addon1"
                          style={{ fontSize: "20px", width: "100%" }}
                          value={address}
                          onFocus={() => setFocused("address")}
                          onChange={onAddressChange}
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div
                style={{
                  height: "100%",
                  padding: "15px",
                  marginLeft: "auto",
                  marginRight: "0",
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <KeyboardWrapper
                  keyboardRef={keyboard}
                  onChange={onKeyboardChange}
                />
              </div>
            </>
          </Modal.Body>
          <Modal.Footer className="modal-footer">
            <div className="row">
              <div className="col">
                <Button
                  variant="primary"
                  style={{ width: 100, height: 50 }}
                  onClick={() => clear()}
                >
                  Clear
                </Button>{" "}
                <Button
                  style={{ width: 100, height: 50 }}
                  variant="info"
                  onClick={() => refreshRecentCalls()}
                >
                  Refresh
                </Button>{" "}
                <Button
                  variant="secondary"
                  onClick={() => props.close()}
                  style={{ width: 100, height: 50 }}
                >
                  Cancel
                </Button>
              </div>
            </div>
            <div className="mr-auto"></div>
            <div>
              <Button
                className="btn btn-success btn-lg"
                onClick={() => takePayment()}
                style={{ width: 150, height: 50 }}
              >
                Continue
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  };

  function takePayment() {
    let customer: ICustomer = {
      id: 0,
      name: name,
      addressLines: [address],
      telephone: telephone,
      postcode: postcode,
      endComment: "",
      uuid: GenerateGUID(),
      address: address,
    };

    context.Customer.setCustomer(customer);

    clear();
    props.takePayment();
  }

  function clear() {
    setName("");
    setTelephone("");
    setPostcode("");
    setAddress("");
  }

  return (
    <>
      <>{customerModal()}</>
    </>
  );
};
