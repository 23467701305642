import React, { useContext, useEffect, useState } from "react";
import ApplicationContext from "../../context/ApplicationContext";
import { IManagerProps } from "./IManagerProps";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import { Epos } from "../pages/epos";
import { Login } from "../pages/login";

import "./styles/bootstrap.css";
import "./style.css";

export const Manager: React.FC<IManagerProps> = (
  props: IManagerProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [vWidth, setWidth] = useState(window.innerWidth);
  const [vHeight, setHeight] = useState(window.innerHeight);
  const [loginView, setLoginView] = useState(true);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleLoginSuccess = () => {
    setLoginView(false);
  };

  const pages = (): JSX.Element => (
    <>
      <>
        {loginView ? (
          <Login successful={handleLoginSuccess} />
        ) : (
          <>
            <Epos />
          </>
        )}
      </>
    </>
  );

  return <>{pages()}</>;
};
