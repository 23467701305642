import React, { useContext, useEffect, useState } from "react";
import "./Style.css";

import ApplicationContext from "../../../context/ApplicationContext";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { getSettings } from "../../../services/Service";
import loadingImage from "../../../images/loading.gif";
import { IProduct } from "../../../types/IProduct";
import { ProductOptions } from "../../productOptions";
import { ReceiptManager } from "../../receiptManager";
import { IOption } from "../../../types/IOption";
import { IReceiptRow } from "../../../types/IReceiptRow";
import { IInstruction } from "../../../types/IInstruction";
import { ITableData } from "../../../types/ITableData";
import { TelephoneManger } from "../../telephoneManger";
import { ISettingsProps } from "../../receiptManager/dialogs/ISettingsProps";
import { SettingsModal } from "../../receiptManager/dialogs/SettingsModal";
import { GenerateGUID } from "../../../helpers/Helper";
import { InstructionView } from "../../instruction/InstructionView";
import { IInstructionViewProps } from "../../instruction/IInstructionViewProps";
import { defaultStyleSettings } from "../../../types/IStyleSettings";
import { ISiteProps } from "../../../configurations/ISiteProps";
import { SiteModal } from "../../../configurations/SiteModal";

import { IEposProps } from "./IEposProps";
import { useParams } from "react-router";
import { IShowHideProps } from "../../../configurations/showHideMenu/IShowHideProps";
import { ShowHideModal } from "../../../configurations/showHideMenu/ShowHideModal";

export const Epos: React.FC<IEposProps> = (props: IEposProps): JSX.Element => {
  const defaultInstructions: IInstruction[] = [];

  const context = useContext(ApplicationContext);
  const [loginView, setLoginView] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [productOptionView, setProductOptionView] = React.useState(false);
  const [showInstructions, setShowInstructions] = React.useState(false);
  const [instructions, setInstructions] = React.useState(defaultInstructions);
  const [showSiteModal, setShowSiteModal] = React.useState(false);
  const [showHideMenuBar, setShowHideMenuBar] = React.useState(false);
  const defaultTableData: ITableData[] = [];
  const [tableData, setTableData] = React.useState(defaultTableData);
  const defaultProduct: IProduct[] = [];
  const [parentId, setParentId] = React.useState(1);
  const [products, setProducts] = React.useState(defaultProduct);
  const [transactionType, setTransactionType] = React.useState(true);
  const [refresh, setRefresh] = React.useState("");
  const [vWidth, setWidth] = useState(window.innerWidth);
  const [vHeight, setHeight] = useState(window.innerHeight);
  const [rowId, setRowId] = useState("");
  const [showSettings, setShowSettings] = React.useState(false);
  const [customStyle, setCustomStyle] = React.useState(defaultStyleSettings);
  const { loadData } = useParams();

  function showProductView() {
    setProductOptionView(true);
  }

  async function OrganisationSettings() {
    setLoading(true);

    const response = await getSettings(context.AuthenticatedUser.getToken());

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.BranchMenu.setMenu(response.menu);

    context.Configuration.setSettings(response.branchSettings);
    setCustomStyle(response.branchSettings.styleSettings);
    setProducts(context.BranchMenu.getMenu().products);

    setLoading(false);
  }

  useEffect(() => {
    OrganisationSettings();
  }, []);

  useEffect(() => {
    let filter: IProduct[] = [];

    if (context.BranchMenu.getMenu() === undefined) {
      return;
    }
    if (context.BranchMenu.getMenu().products === undefined) {
      return;
    }

    context.BranchMenu.getMenu().products.forEach((p: IProduct) => {
      if (p.parentId === parentId) {
        filter.push(p);
      }
    });

    setProducts(filter);
  }, [parentId]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function hasOptions(id: number) {
    const allOptions = context.BranchMenu.getMenu().options;

    let filteredOptions: IOption[] = [];
    for (let i = 0; i < allOptions.length; i++) {
      if (allOptions[i].productId == id) {
        filteredOptions.push(allOptions[i]);
      }
    }
    if (filteredOptions.length === 0) {
      return false;
    }
    return true;
  }

  function forceInstructions(id: number) {
    const allInstructions = context.BranchMenu.getMenu().instructions;

    let instructions: IInstruction[] = [];
    let forceOpen = false;

    for (let i = 0; i < allInstructions.length; i++) {
      if (allInstructions[i].productId == id) {
        if (allInstructions[i].required == true) forceOpen = true;

        instructions.push(allInstructions[i]);
      }
    }
    if (forceOpen === false) {
      return undefined;
    }

    return instructions;
  }

  function onProductClick(id: number) {
    const product = findProductByProductId(id);

    if (product === undefined) {
      return;
    }

    if (product.isCategory && product.price === 0) {
      setParentId(product.id);
      return;
    }

    context.Data.setSelectedProductId(id);

    if (hasOptions(id)) {
      showProductView();
      return;
    }

    //add to receipt
    const receiptRow: IReceiptRow = {
      id: context.Receipt.getRows().length + 1,
      product: product,
      variations: [],
      extras: [],
      commonOptions: [],
      notes: "",
      instructions: [],
      description: [],
      price: product.price,
      qty: 1,
      isDiscount: false,
      options: [],
      uuid: GenerateGUID(),
    };
    context.Receipt.addRow(receiptRow);

    setInstructions([]);
    setTableData([]);

    setRowId(receiptRow.uuid);

    if (product.isCategory && product.price > 0) {
      setParentId(product.id);
      return;
    }

    let instructs = forceInstructions(product.id);
    if (instructs === undefined) {
      setRefresh(GenerateGUID());
      return;
    }

    // setInstructions(instructs);
    setShowInstructions(true);
    setRefresh(GenerateGUID());
  }

  const listProducts = (parentId: number): JSX.Element => (
    <>
      <div className="container-fluid">
        <div className="row">
          <>{showBackButton()}</>
          {products
            .sort((a, b) => (a.position > b.position ? 1 : -1))
            .map((p) =>
              p.parentId === parentId && p.description !== "Home" ? (
                <>
                  <div style={{ padding: 1 }}>
                    <Button
                      variant="outline-primary bt-lg"
                      style={{
                        width: customStyle.menuButtonWidth,
                        height: customStyle.menuButtonHeight,
                        fontWeight: "bolder",
                        backgroundColor: p.buttonColour,
                        borderRadius: "10px",
                        fontSize: customStyle.menuButtonFontSize,
                      }}
                      onClick={() => onProductClick(p.id)}
                      key={p.id}
                    >
                      <div style={{ color: p.textColour }}>{p.description}</div>
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )
            )}
        </div>
      </div>
    </>
  );

  function showProduct(previousId: number) {
    setProductOptionView(false);
    listProducts(previousId);
  }

  function getProduct() {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === parentId;
    });
  }

  function showPreviousView() {
    setProductOptionView(false);
    const p = getProduct();
    if (p === undefined) {
      return;
    }
    setParentId(p.parentId);
  }

  const showBackButton = (): JSX.Element => (
    <>
      {parentId === 1 ? (
        <></>
      ) : (
        <>
          <Button
            className="mr-1"
            variant="outline-primary bt-lg"
            style={{
              width: customStyle.menuButtonWidth,
              height: customStyle.menuButtonHeight,
              fontWeight: "bolder",
              //backgroundColor: p.,
              // backgroundColor: p.buttonColour,
              borderRadius: "10px",
              fontSize: customStyle.menuButtonFontSize,
            }}
            onClick={() => showPreviousView()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="42"
              height="42"
              fill="currentColor"
              className="bi bi-rewind-btn"
              viewBox="0 0 16 16"
            >
              <path d="M7.21 5.093A.5.5 0 0 1 8 5.5v1.886l3.21-2.293A.5.5 0 0 1 12 5.5v5a.5.5 0 0 1-.79.407L8 8.614V10.5a.5.5 0 0 1-.79.407l-3.5-2.5a.5.5 0 0 1 0-.814z" />
              <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm15 0a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z" />
            </svg>
          </Button>
        </>
      )}
    </>
  );

  const productListing = (): JSX.Element => (
    <>
      <div>
        <div
          style={{
            overflowY: "auto",
            padding: 7,
            height: "90vh",
          }}
        >
          {productOptionView ? (
            <>
              <ProductOptions
                showProducts={showProduct}
                triggerRefresh={() => setRefresh(GenerateGUID())}
              />
            </>
          ) : (
            <>{listProducts(parentId)}</>
          )}
        </div>
      </div>
    </>
  );

  function onMenuClick() {
    setShowSettings(true);
  }

  const buttonStyle = {
    width: "15vw",
    height: "15vw",
    fontSize: "2vw",
    fontWeight: "bolder",
    backgroundColor: "purple",
    borderRadius: "30px",
  };

  function setType(value: string) {
    context.Receipt.clear();
    context.Receipt.setTransactionType(value);
    setTransactionType(false);
  }

  const transactionTypeView = (): JSX.Element => (
    <>
      <nav
        className="navbar navbar-light"
        style={{ backgroundColor: "#e3f2fd" }}
      >
        <a className="navbar-brand">WaEPOS</a>
        <button
          type="button"
          className="btn btn-outline-primary btn-lg"
          onClick={onMenuClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </button>
      </nav>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
        }}
      >
        <Button
          className="mr-1"
          style={buttonStyle}
          onClick={() => setType("Eat in")}
        >
          Eat in
        </Button>
        <Button
          className="mr-1"
          style={buttonStyle}
          onClick={() => setType("Takeout")}
        >
          Takeout
        </Button>
        <Button
          className="mr-1"
          style={buttonStyle}
          onClick={() => setType("Collection")}
        >
          Collection
        </Button>
        <Button
          className="mr-1"
          style={buttonStyle}
          onClick={() => setType("Delivery")}
        >
          Delivery
        </Button>
      </div>
    </>
  );

  function homeMenu() {
    setProductOptionView(false);
    setParentId(1);
  }
  function transactionTypes() {
    setTransactionType(true);
    setParentId(1);
  }

  const myStyle = {
    width: vWidth,
    verticalAlign: "top",
    height: "100%",
  };

  const template = (): JSX.Element => (
    <>
      {transactionType ? (
        transactionTypeView()
      ) : (
        <>
          <table style={myStyle}>
            <tr
              style={{
                verticalAlign: "top",
                height: "100%",
                width: vWidth - customStyle.receiptWidth,
              }}
            >
              <td
                style={{
                  verticalAlign: "top",
                  height: "100%",
                  width: vWidth - customStyle.receiptWidth,
                }}
              >
                <Navbar
                  className="navbar navbar-light"
                  style={{ backgroundColor: "#e3f2fd" }}
                >
                  <Navbar.Brand>
                    <Form>
                      <InputGroup>
                        <InputGroup.Text
                          style={{ backgroundColor: "#e3f2fd", fontSize: 16 }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-search"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                          </svg>
                        </InputGroup.Text>
                        <Form.Control
                          placeholder="Search ... "
                          aria-describedby="basic-addon1"
                          style={{ backgroundColor: "white", fontSize: 16 }}
                        />
                      </InputGroup>
                    </Form>
                  </Navbar.Brand>
                  <Navbar.Toggle />
                  <Navbar.Collapse>
                    <Nav
                      className="justify-content-end"
                      style={{ width: "100%" }}
                    >
                      <NavDropdown title="Settings" id="nav-dropdown">
                        <NavDropdown.Item
                          onClick={() => setShowSiteModal(true)}
                        >
                          Organisation
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => setShowSettings(true)}>
                          Printer
                        </NavDropdown.Item>{" "}
                        <NavDropdown.Item
                          onClick={() => setShowHideMenuBar(true)}
                        >
                          Show/Hide Menu Bar
                        </NavDropdown.Item>
                        <NavDropdown.Item onClick={() => setShowSettings(true)}>
                          Login
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => setShowSettings(true)}>
                          Help
                        </NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>

                {productListing()}
              </td>
              <td
                style={{
                  backgroundColor: "lightgray",
                  width: 1,
                }}
              ></td>
              <td style={{ verticalAlign: "top", height: "100%" }}>
                {
                  <ReceiptManager
                    transactionTypes={transactionTypes}
                    mainMenu={homeMenu}
                    clear={() => setRefresh(GenerateGUID())}
                  />
                }
              </td>
            </tr>
          </table>
        </>
      )}
    </>
  );

  const loadingData = (): JSX.Element => {
    return (
      <>
        {context.ApplicationError.hasError() ? (
          <div className="loading-center">
            {/* <img src={loadingImage} alt="Loading" /> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Cannot get settings ... <br />
            please contact support of the error persists
          </div>
        ) : (
          <div className="loading-center">
            <img src={loadingImage} alt="Loading" />
          </div>
        )}
      </>
    );
  };

  const settingsProps: ISettingsProps = {
    close: function (): void {
      setShowSettings(false);
    },
    show: showSettings,
  };

  const instructionViewProps: IInstructionViewProps = {
    close: function (): void {
      setShowInstructions(false);
    },
    show: showInstructions,
    rowUuid: rowId,
  };

  const siteProps: ISiteProps = {
    close: function (): void {
      setShowSiteModal(false);
    },
    show: showSiteModal,
  };

  const showHideMenuBarProps: IShowHideProps = {
    close: function (): void {
      setShowHideMenuBar(false);
    },
    show: showHideMenuBar,
  };

  return (
    <>
      <>{loading ? loadingData() : <>{template()}</>}</>
      <TelephoneManger />
      <SettingsModal {...settingsProps} />
      <InstructionView {...instructionViewProps} />
      <SiteModal {...siteProps} />
      <ShowHideModal {...showHideMenuBarProps} />
    </>
  );
};
